<template>
  <div class="">
    <v-flex text-right class="my-2">
      <v-btn
        color="red"
        @click="imprimirReporte()"
        class="mx-1"
        :loading="loading"
        :disabled="loading"
        :dark="!loading"
        small
      >
        <v-icon>mdi-file-pdf-box</v-icon> Imprimir
      </v-btn>
    </v-flex>
    <v-data-table :headers="cabecera" :items="quoteOpen"> </v-data-table>
  </div>
  <!-- class="elevation-1"
        select-all

        item-key="id"
        loading="true"
        search="search" -->
</template>

<script>
import funcion from "@/mixins/funciones";
import axios from "axios";
import { mapActions, mapState } from "vuex";
export default {
  mixins: [funcion],
  name: "quoteOpenComponent",
  data() {
    return {
      loading: false,
      cabecera: [
        { value: "created", text: "Fecha" },
        { value: "status", text: "Estatus" },
        { value: "codigo", text: "Código" },
        { value: "ejecutivo_ventas", text: "Vendedor" },
        { value: "nombres", text: "Cliente" },
        { value: "telefono", text: "Teléfono" },
        { value: "sentido", text: "Sentido" },
        { value: "tipo_de_carga", text: "Carga" },
        { value: "incoterms", text: "Incoterms" },
        { value: "origen", text: "Origen" },
        { value: "destino", text: "Destino " },
        { value: "namemarketing", text: "Marketing" },
      ],
    };
  },
  mounted() {
    this.listQuoteOpen();
  },
  computed: {
    ...mapState(["quoteOpen"]),
  },
  methods: {
    ...mapActions(["listQuoteOpen"]),
    async imprimirReporte() {
      this.loading = true;
      let headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "aplication/json",
        "Access-Control-Allow-Methods":
          "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
        responseType: "arraybuffer",
      };
      var data = {
        id_branch: JSON.parse(localStorage.getItem("branch")),
      };
      let url =
        process.env.VUE_APP_URL_REPORT + "listado_cotizacion_abierta_export";
      await axios.post(url, data, headers).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let name = this.uuidv4();
        link.setAttribute("download", name + ".pdf");
        document.body.appendChild(link);
        link.click();
        this.loading = false;
      });
    },
  },
  beforeCreate() {},
};
</script>

<style></style>
